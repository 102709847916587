import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
import Flags from 'country-flag-icons/react/3x2'
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';
import signOut from "../../../../assets/media/images/markt/profil/signOut-w.png";
import addBike from "../../../../assets/media/images/b2b/landingrespo/AddBike-logo.png";
import closebutton from "../../../../assets/media/images/markt/hamburgericon-white.png";
import hamburgericon from "../../../../assets/media/images/markt/hamburgericon.png";
import ebikeboerse_logo_white from "../../../../assets/media/images/markt/E-Bikebörse Logo 3.png";
import group_21 from "../../../../assets/media/images/markt/Group 21_2.png";
import InterB2BAxios from '../../../../Service/InterB2BAxios';

import moment from 'moment';
import styles from '../../../../assets/scss/modules/landingmarkt.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBAutocomplete,
	MDBDropdownMenu,
	MDBDropdownItem,
	MDBDropdown,
	MDBDropdownToggle,
	MDBModal,
	MDBModalHeader,
	MDBModalBody,
	MDBModalFooter,
} from 'mdbreact';
const Parser = require('rss-parser');
const parser = new Parser();
const cookies = new Cookies();
let language = '';
let urlName = '';

class MenuSideNavB2BikeMain extends Component {

	constructor(props) {
    super(props);
		this.interAxios = new InterB2BAxios(this, 'LandingPage');
    this.state = {
									};
  };





	componentDidMount() {
		var params = window.location.href.split(".");
		language = params[params.length - 1];
	}

	componentWillUnmount() {
	}

	setBackendLanguage = (language) => {
		this.interAxios.get('/api/user/setLanguage', { params: { language: language}}, function(response) {
			console.log('Language set to ' + language)
		})
	}

	toGerman = () => {
		i18next.changeLanguage('de', (err, t) => {
			if (err) return console.log('something went wrong loading', err);
			this.setBackendLanguage('de');
			//this.setState({language: 'de'});
		});
		moment.locale('de');
		//this.onLanguageDropFalse();
	}

	toEnglish = () => {
		i18next.changeLanguage('en', (err, t) => {
			if (err) return console.log('something went wrong loading', err);
			this.setBackendLanguage('en');
			//this.setState({language: 'en'});
		});
		moment.locale('en');
		//this.onLanguageDropFalse();
	}

	getEBikeBoerseNavItem = () => {
		if (this.props.userData.isRemoteB2BPage()) {
			return <MDBNavItem>
				<a href={"https://e-bikeboerse.de" + this.props.userData.getBaseName() + "?interb2bike=true"} className="nav-link Ripple-parent">
						<img alt="" className="main-logo" src={ebikeboerse_logo_white}></img>
				</a>
			</MDBNavItem>;
		} else {
			return <MDBNavItem>
				<MDBNavLink link to="/LandingPageMarkt" role="tab">
						<img alt="" className="main-logo" src={ebikeboerse_logo_white}></img>
				</MDBNavLink>
			</MDBNavItem>
		}
	}


  render() {
//		console.log(this.state.redirect);
		const { t } = this.props;
    const client = this.props.client;

    return (
			<div className="menu-modal">
			<MDBCard className="landingb2bResponziv-nav-container">
      <MDBCardBody>
					<MDBRow start className="hamburegermenu-row-2">
					<MDBCol size="6" className="p-0 text-left">
						<MDBNav className="logo-nav">
							<MDBNavItem>
								<MDBNavLink link to="/MainPageHandler" active={this.props.activeItem === "1"} onClick={this.props.onItemClose} role="tab" >
										<img alt="" className="group-21-logo" src={group_21}></img>
								</MDBNavLink>
							</MDBNavItem>
						</MDBNav>
					</MDBCol>
					<MDBCol size="6" className="p-0 text-right">
						<MDBBtn
							className="hambureger-menu"
							flat
							type="button"
							onClick= {this.props.onMenuClose}
						>
							<img alt="" className="hambureger-icon" src={closebutton}></img>
						</MDBBtn>
					</MDBCol>
					</MDBRow>
					<MDBNav className="landingb2b-2-nav flex-column">
						<MDBNavItem>
							<MDBNavLink link to="/NewsPageHandler"
													active={this.props.activeItem === "2"}
													onClick={this.props.onItemClose}
													role="tab"
													disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false} >
									<span>News</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="/BlogPageHandler"
													active={this.props.activeItem === "3"}
													onClick={this.props.onItemClose}
													role="tab"
													disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false} >
									<span>Blog</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="/CompaniesPageHandler"
													active={this.props.activeItem === "4"}
													onClick={this.props.onItemClose}
													role="tab"
													disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false} >
									<span>{t('Hersteller')}</span>
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink link to="/SalonCalendarPage"
													active={this.props.activeItem === "5"}
													onClick={this.props.onItemClose}
													role="tab"
													disabled={this.props.userData.isCurrentCompanyAnonim() ? true :false} >
									<span>Events</span>
							</MDBNavLink>
						</MDBNavItem>
						<div className="linie"/>
						<MDBNavItem>
							<MDBNavLink link to={!this.props.userData.isCurrentCompanyAnonim() && (this.props.userData.getMyInvitationslist() === undefined || this.props.userData.getMyInvitationslist().length === 0) ? "/MyProfilePageB2B" :
							 										 this.props.userData.isCurrentCompanyAnonim() && (this.props.userData.getMyInvitationslist() === undefined || this.props.userData.getMyInvitationslist().length === 0) ?  "/NewCompanyB2B" :
																	 this.props.userData.isCurrentCompanyAnonim() && (this.props.userData.getMyInvitationslist() !== undefined && this.props.userData.getMyInvitationslist().length > 0) ?  "/InvitationB2BIn" : "#"}
													role="tab"
													onClick={this.props.onItemClose} >
							{this.props.userData.getCurrentCompany()?.profileUrl !== undefined ?
								<img className="userProfileName-icon"
									src={this.props.userData.getCurrentCompany()?.profileUrl +'?' + Date.now()}
									/>:<>
									{this.props.userData.getMyUser()?.profileUrl !== undefined ?
										<img alt="" className="user-photo-navbar" src={this.props.userData.getMyUser()?.profileUrl +'?' + Date.now()}></img>:
										<div className="userProfileName-icon">{this.props.userData.userProfilName()}</div>}</>}
							</MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
						<MDBNavLink link to="/Logout" active={this.props.activeItem === "11"} onClick={this.props.onItemClose} role="tab" >
							<MDBBtn
								flat
								className="p-0 m-0"
							>
								<MDBRow className="m-0">
								<MDBRow className="m-0">
									<MDBCol middle md="auto" size="auto" className="px-0 col-height">
										<img alt="" className="profil-logo-modal" src={signOut}></img>
									</MDBCol>
									<MDBCol middle className="pl-2 pr-2 col-height">
										<span className="anmelden-text text-capitalize">{t('abmelden')}</span>
									</MDBCol>
								</MDBRow>
								</MDBRow>
							</MDBBtn>
						</MDBNavLink>
					</MDBNavItem>
					</MDBNav>
					{!this.props.userData.isCurrentCompanyAnonim() ?
					<MDBNav className="landingb2b-nav flex-column">
					<div className="linie mt-0"/>
						{this.getEBikeBoerseNavItem()}
						<Link  to={this.props.userData.isLoggedIn() ? "/AddBikeB2B" : "/SignInB2B"} >
							<MDBBtn
								className="addBike-btn-landingb2b"
								flat
								type="button"
								onClick= {this.props.onMenuClose}
							>
								<img alt="" className="addBike-logo" src={addBike}></img><br/>
							</MDBBtn>
						</Link>
					</MDBNav>:false}
					<div className="currentcompany-nav-container">
					<MDBRow center>
					<MDBNav className="justify-content-center currentcompany-nav">
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'en'} onClick={this.toEnglish} role="tab">
							<Flags.GB style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
					<MDBNavItem>
						<MDBNavLink  id="flag" link className="pt-3" to="#" active={this.props.i18n.language === 'de'} onClick={this.toGerman} role="tab">
							<Flags.DE style={{borderRadius: "2px", width: "30px"}}/>
						</MDBNavLink>
					</MDBNavItem>
					</MDBNav>
					</MDBRow>
					</div>
					<MDBNav className="justify-content-center form-footer-respo py-4">
						<MDBNavItem>
							<MDBNavLink to={{
												pathname: "/AboutB2B",
												state: { urlName: this.props.urlName }}} onClick= {this.props.onMenuClose}><span>{t('aboutUs')}</span></MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink to="/ImpressumB2B"><span onClick= {this.props.onMenuClose}>{t('impressum')}</span></MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink to="/PrivacyPolicyB2B"><span onClick= {this.props.onMenuClose}>{t('privacyPolicy')}</span></MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink to="/UserAgreementB2B"><span onClick= {this.props.onMenuClose}>{t('termsOfUse')}</span></MDBNavLink>
						</MDBNavItem>
						<MDBNavItem>
							<MDBNavLink to="/CookiePolicyB2B"><span onClick= {this.props.onMenuClose}>{t('cookiePolicy')}</span></MDBNavLink>
						</MDBNavItem>
					</MDBNav>
				</MDBCardBody>
				</MDBCard>
				</div>
 	);
 }
 }

 export default  withTranslation()(MenuSideNavB2BikeMain);
