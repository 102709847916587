import React, { Component } from "react";
import ReactDOM from 'react-dom';
import ReactFullpage from '@fullpage/react-fullpage';
import { Redirect, Link } from 'react-router-dom';
//import { Scrollbar } from "react-scrollbars-custom";
import { withTranslation, Trans } from 'react-i18next';
import i18next from 'i18next';

import logo from "../../../assets/media/logo/1.png";
import profile_Boda from "../../../assets/media/images/b2b/profile_Boda.jpg";

import group_3 from "../../../assets/media/images/b2b/landingrespo/Group 3.png";
import group_1 from "../../../assets/media/images/b2b/landingrespo/Group 1.png";
import rectangle_2 from "../../../assets/media/images/b2b/landingrespo/Rectangle-2.png";

import group_2 from "../../../assets/media/images/b2b/landingrespo/Group 2.png";
import group_6 from "../../../assets/media/images/b2b/landingrespo/Group 6.png";
import rectangle_1 from "../../../assets/media/images/b2b/landingrespo/Rectangle-1.png";

import group_7 from "../../../assets/media/images/b2b/Group 7.png";
import group_8 from "../../../assets/media/images/b2b/Group 8.png";

import rectangle_3 from "../../../assets/media/images/b2b/landingrespo/Rectangle-3.png";

import e_Bikeborse from "../../../assets/media/images/b2b/E-Bikebörse.png";
import appentum from "../../../assets/media/images/b2b/Appentum.png";
import studiCore from "../../../assets/media/images/b2b/StudiCore.png";
import interb2b from "../../../assets/media/images/b2b/Interb2b.png";
import logo_first from "../../../assets/media/images/markt/profil/Group 7.png";
import InterB2BAxios from '../../../Service/InterB2BAxios';
import MenuSideNavB2Bike from './MenuSideNavB2Bike';
import FooterB2BLanding from '../../../components/Footer/FooterB2BLanding';
import LandingPageB2BikeNavRespo from '../../../components/NavbarComponentB2B/LandingPageB2BikeNavRespo';
import BoersePageNavRespo from '../../../components/NavbarComponent/BoersePageNavRespo';
import styles from '../../../assets/scss/modules/landingB2b.module.scss';
import Cookies from 'universal-cookie';

import{
	MDBContainer,
	MDBRow,
	MDBCol,
	MDBCard,
	MDBCardHeader,
	MDBCardBody,
	MDBScrollbar,
  MDBInput,
  MDBBtn,
  MDBNavbar,
  MDBNavbarBrand,
  MDBNavbarToggler,
  MDBCollapse,
  MDBNavItem,
  MDBNavLink,
	MDBNav,
	MDBFormInline,
	MDBSideNav
} from 'mdbreact';

const cookies = new Cookies();
let language = '';
let urlName = '';
class LandingPageB2BikeResponziv extends Component {

	constructor(props) {
    super(props);
		this.listInnerRefInterB2B = React.createRef();
		this.listInnerRefEBikeBoerse = React.createRef();
		this.listInnerRefContact = React.createRef();
		this.interAxios = new InterB2BAxios(this, 'LandingPageB2Bike');
    this.state = {
								  activeItem: "1",
									activeVideoItem: "1",
									navbarPaddingTop: true,
									navbarPaddingMiddle: false,
									navbarPaddingBottom: false,
									loggedIn: false
									};
  };
	componentDidMount() {
		this.onRefInterB2B();
		var params = window.location.href.split(".");
    language = params[params.length - 1];
		var params2 = window.location.href.split("/");
		urlName = params2[params2.length - 1];
		document.addEventListener("scroll", this.scrollEventListener);
	}
	componentWillUnmount() {
		this.interAxios.cancel();
		document.removeEventListener("scroll", this.scrollEventListener);
	}

	toggle = tab => e => {
				this.props.onEnableScrollMain();
				if (this.state.activeItem !== tab) {
					this.setState({
						activeItem: tab});
					if (tab == 3) {
						this.onRefContact();
					}
					if (tab == 2) {
						this.onRefEBikeBoerse();
					}
					if (tab == 1) {
						this.onRefInterB2B();
					}
				}

	}

	onEmailChange = (event) =>{
    this.setState({email: event.target.value});
  };

  onSubmit = (event) =>{
    event.preventDefault();
    let self = this;
    this.interAxios.post('/api/email/informations', {email: this.state.email}, function(response) {
     })
  };

	onRefEBikeBoerse = () => {
		this.listInnerRefEBikeBoerse.current.scrollIntoView({
 	 });
	}

	onRefInterB2B = () => {
		this.listInnerRefInterB2B.current.scrollIntoView({
	 });
	}

	onRefContact = () => {
		this.listInnerRefContact.current.scrollIntoView({
	 });
	}

	scrollEventListener = (event) => {
		const navPadding = window.scrollY < 500 ? true : false;
		const tabscroll = window.scrollY < 670 ? "1" : window.scrollY < 1400 ? "2" : window.scrollY < 2100 ? "3" : "4";
		this.setState({ navbarPaddingTop: navPadding,  activeItem: tabscroll});
	}

  render() {
		const { t } = this.props;
    const client = this.props.client;
		let innerDiv = <div className="navlink-search2 text-right">
			<MDBBtn
					className="signin-btn-borse"
					type="submit"
					onClick={() => this.props.userData.onLogout()}
					>e-Bikebörse</MDBBtn>
		</div>;

		let link;
		if (this.props.userData.isRemoteB2BPage()) {
			link = <a href={"https://e-bikeboerse.de" + this.props.userData.getBaseName() + "?interb2bike=true"} className="nav-link Ripple-parent">{innerDiv}</a>;
		} else {
			link = <MDBNavLink link to="/LandingPageMarkt" role="tab" >{innerDiv}</MDBNavLink>;
		}
    return (
			<>

				<MDBContainer fluid className="navbarcomponentRespo-nav-container-b2b">
				<LandingPageB2BikeNavRespo  userData={this.props.userData} onMenuOpen = {this.props.handleSideNavToggle}/>
					<div ref={this.listInnerRefInterB2B}></div>
					<div className={styles.bodywrapper2}>
						<div className="side-nav-menu-b2b">
							<MDBSideNav
									right
									triggerOpening={this.props.isSideNavOpen}
									onOverlayClick={this.props.handleSideNavToggle}
									breakWidth={1300}
									className="deep-purple darken-4"
							>
								<MenuSideNavB2Bike
										userData={this.props.userData}
										toggle = {this.toggle}
										onMenuClose = {this.props.onEnableScroll}
										onItemClose = {this.props.onEnableScrollMain}
										activeItem = {this.state.activeItem}
								/>
							</MDBSideNav>
						</div>
						<MDBRow start className="m-0 pb-3">
							<img className="landingpage-first-bild" src={group_3}/>
						</MDBRow>
						<MDBRow center className="m-0 pt-2 py-1">
							<img className="landingpage-second-bild" src={group_1}/>
						</MDBRow>
						<MDBRow start className="m-0 py-2">
							<MDBCol middle size="1" className="p-0 m-0">
								<img className="landingpage-rectangle-1" src={rectangle_2}/>
							</MDBCol>
							<MDBCol middle size="11" className="p-0 m-0">
								<span className="landingpage-text-b2b">{t('stop_searching')}</span>
							</MDBCol>
						</MDBRow>
						<MDBRow center className="m-0 py-2">
							<span className="landingpage-title">{t('join_to_the_interb2b_platform')}</span>
						</MDBRow>
						<MDBRow center className="m-0">
							<MDBCol size="6" className="p-0 m-0 text-center">
								<Link  to="/SignUpHersteller" className="pl-1">
									<MDBBtn
										className="signin-btn-supplier"
										color="primary"
										type="submit"
										>{t('as_a_supplier')}</MDBBtn>
								</Link>
							</MDBCol>
							<MDBCol size="6" className="p-0 m-0 text-center">
								<Link  to="/SignUpHandler" className="pl-1">
									<MDBBtn
										className="signin-btn-dealer"
										type="submit"
										>{t('as_a_dealer')}</MDBBtn>
								</Link>
							</MDBCol>
						</MDBRow>
					</div>
					<div ref={this.listInnerRefEBikeBoerse}></div>
					<div className={styles.bodywrapper3}>
						<MDBRow center className="m-0 pb-4">
							<img className="landingpage-first-bild-bike" src={group_6}/>
						</MDBRow>
						<MDBRow center className="m-0 pt-2 py-3">
							<img className="landingpage-second-bild-bike" src={group_2}/>
						</MDBRow>
						<MDBRow start className="m-0 py-2">
							<MDBCol middle size="1" className="p-0 m-0">
								<img className="landingpage-rectangle-1" src={rectangle_1}/>
							</MDBCol>
							<MDBCol middle size="11" className="p-0 m-0">
								<span className="landingpage-text-b2b">{t('deutschlands_marktplatz')}</span>
							</MDBCol>
						</MDBRow>
						<MDBRow center className="m-0 py-2">
							<span className="landingpage-title">{t('Beitritt_zur_E_Bikeboerse')}</span>
						</MDBRow>
						<MDBRow center className="m-0">
						<MDBNav className="landingb2b-nav">
						<MDBNavItem>
							{link}
						</MDBNavItem>
						</MDBNav>
						</MDBRow>
					</div>
					<div className={styles.bodywrapper4}>
					<div ref={this.listInnerRefContact}></div>
						<MDBRow center className="m-0 py-4 px-3">
							<MDBCol size="3" middle className="m-0 p-0">
								<img className="profile" src={profile_Boda}></img>
							</MDBCol>
							<MDBCol size="auto" middle className="m-0 p-0">
							<span className="balazs-boda-interb-2-b-respo font-weight-bolder">balazs.boda@interb2b.com</span><br/>
							<span className="balazs-boda-interb-2-b-respo">({t('projektleitung_der_produktentwicklung')})</span><br/>
							</MDBCol>
						</MDBRow>
						<MDBRow start className="m-0 py-3 px-3">
							<MDBCol middle size="1"  className="m-0 p-0">
								<img className="rectangle-1" src={rectangle_3}></img>
							</MDBCol>
							<MDBCol middle size="11" className="m-0 p-0">
								<span className="liebe-interessenten">{t('Finden_Sie_Ihren_besten_Teilelieferanten')}</span>
							</MDBCol>
						</MDBRow>
						<MDBRow center className="py-3 m-0">
							<img className="firmen" src={interb2b}></img>
						</MDBRow>
						<MDBRow center className="py-3 m-0">
								<a 	style={{textAlign:"center"}}
								href="https://www.studicore.hu/about"
										target="_blank" rel="noopener noreferrer">
										<img className="firmen" src={studiCore}></img>
								</a>
						</MDBRow>
						<MDBRow center className="pt-3 m-0 footer-row-bottom">
							<a 	style={{textAlign:"center"}}
									href="https://appentum.de"
									target="_blank" rel="noopener noreferrer">
									<img className="firmen" src={appentum}></img>
							</a>
						</MDBRow>
						<div className="rectangle-footer-markt-respo">
							<MDBContainer fluid>
								<FooterB2BLanding urlName = {urlName}/>
							</MDBContainer>
						</div>
					</div>
				</MDBContainer>
			{this.state.loggedIn ? <Redirect to='/MainPage'/>:false}
			</>
    );
  }
}

export default  withTranslation()(LandingPageB2BikeResponziv);
